<template>
  <div>
  <ul :id="'company-node-'+parent" >
      <li v-for="person in nodes" :id="'person-'+person.hashid" v-if="person.hashid != parent" @click.prevent.stop="openDetails(person['objectives'])" class="before-expand">
        <b-card class="relative mb-0 company-dept">
          <!-- <div class="emp-pic">
            <b-img :src="person.avatar" class="pro-img" fluid rounded="circle" alt="" @click="viewEmployee = person ; showEmpInfoSidebar = true;"></b-img>
          </div>
          <div class="tree-content">
            <div class="mb-0 mt-1">
              <div class="goal-header mt-2">
                <h5 class="mb-0 emp-name font-weight-bolder" v-b-tooltip.hover.v-default :title="person.name">{{person.name}}</h5>
                <h6 class="description mt-25 small">{{person.designation}}</h6>
              </div>
            </div>
          </div> -->
           <!-- Static New Design -->
           <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="emp-logo d-flex align-items-center gap-6">
                    <div class="company-logo">
                      <b-img :src="person.avatar" class="pro-img" fluid rounded="circle" alt="company logo"></b-img>
                    </div>
                    <div class="person-details">
                      <h2 class="mb-0">{{ person.name }}</h2>
                      <small> {{ person.designation }} </small>
                    </div>
                  </div>
                  <div class="progress-state okr-status">
                    <div class="circle_percent" :percent="Number(person['overall_percentage'])">
                      <div class="circle_inner">
                        <div class="round_per"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center objective-objtype">
                  <div class="obj-name d-flex align-ites-center">
                    <div class="quarter-data">
                      <h6 class="mb-0 text-left">No Of Objectives</h6>
                      <p class="value text-left mb-0">{{person['objectiveCount']}}</p>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="35" viewBox="0 0 2 35" fill="none">
                    <path d="M1 1L1 34" stroke="#D0D5DD" stroke-linecap="round"/>
                  </svg>
                  <div class="obj-period d-flex align-ites-center">
                    <div class="quarter-data">
                      <h6 class="mb-0 text-left">Quarter</h6>
                      <p class="value text-left mb-0">{{selectedPeriod.quarter}} {{selectedPeriod.year}}</p>
                    </div>
                  </div>
                </div>
                <!-- No OKR Added -->
                <!-- <h6 class="text-center text-danger w-100 pb-2">No OKR added</h6> -->
                <div class="w-100 overall-progress pb-50">
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="mb-0">Overall Progress</h6>
                    <span class="ml-50">{{Number(person['overall_percentage'])}}%</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <b-progress :value="Number(person['overall_percentage'])" max="100" class="flat-progress w-100"></b-progress>
                  </div>
                </div> 
                </hr>
                <!-- Expanded List -->
                <div class="expand-list border-top pt-50 mt-50">
                  <div class="d-flex align-items-center justify-content-between list-unstyled my-25 gap-6" v-for="objective in person['objectives']" @click.prevent.stop="openDetails([objective])">
                    <h6 class="mb-0">{{objective.title}}</h6>
                    <div class="progress-state">
                      <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-button v-if="person.teammates && person.teammates.length" :id="'arrow-icon-'+person.hashid" class="expand-icon" @click.prevent.stop="reportingListClicked(person.hashid);" >
                  <feather-icon icon="PlusIcon" size="18" class="before-expand m-0" />
                  <feather-icon icon="MinusIcon" size="18" class="after-expand m-0" />
                </b-button>
        </b-card>
        <nodes v-if="person.teammates &&  person.teammates.length" :parent="person.hashid" :parentData="person" :ref="'children-'+person.hashid"  :selectedPeriod="selectedPeriod" />
      </li>
      <!-- <li class="hr-info" v-for="department in getNodes(nodes,'department')" >
        <div class="company-dept">
          <b-button class="hracc-details">{{department[0]}} <b-badge>{{department[1].length}}</b-badge></b-button>
        </div>
        <ul>
          <li class="sub-main" v-for="employee in department[1]" :id="'person-'+employee.hashid">
            <b-card class="relative mb-0 company-dept">
              <div class="emp-pic">
                <b-img :src="employee.avatar" class="w-100 pro-img" fluid rounded="circle" alt="profile photo"></b-img>
              </div>
               <div class="tree-content">
                <div class="mb-0 my-1">
                  <div class="goal-header text-center mt-2">
                    <h5 class="mb-0 emp-name font-weight-bolder" v-b-tooltip.hover.v-default :title="employee.name">{{employee.name}}</h5>
                    <h6 class="description mt-25 small">{{employee.designation}}</h6>
                  </div>
                </div>
              </div>
              <div class="view-count d-flex align-items-center justify-content-between">
                <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="people" scale="1" class="mr-50"></b-icon> {{employee['employees_count']}}</b-badge>
                <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="building" scale="1" class="mr-50"></b-icon> {{employee['departments_count']}}</b-badge>
                  </div>
                  <b-button v-if="employee.teammates && employee.teammates.length" :id="'arrow-icon-'+employee.hashid" class="expand-icon"><feather-icon icon="ChevronDownIcon" size="18" class="text-primary font-weight-bolder m-0" @click="listClicked(employee.hashid);"/></b-button>
            </b-card>

            <nodes v-if="employee.teammates && employee.teammates.length" :parent="employee.hashid" :parentData="employee" :ref="'children-'+employee.hashid"/>
          </li>
        </ul>
      </li> -->
    </ul>
    <b-sidebar 
    :id="parent+'-profile-view-popover'"
    :visible="showEmpInfoSidebar"
    v-if="viewEmployee"
    :ref="parent+'-profile-view-popover'" bg-variant="white" shadow  no-header right width="35%"
      title="Employee Profile">
      <template>
        <!-- Header -->
        <div class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-2
              border-bottom
              sticky-top
            ">
          <h3 class="mb-0 font-weight-bold">Employee Profile</h3>
          <div>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
              @click="showEmpInfoSidebar = false">
              <feather-icon size="20" icon="XCircleIcon" />
            </b-button>
          </div>
        </div>
        <!-- BODY -->

        <b-card
          :img-src="require('@/assets/images/banner/banner-'+viewEmployee.banner_id+'.jpg')"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile sidebar-pop"
        >
          <div class="profile-image-wrapper ">
            <div class="profile-image p-0">
              <b-avatar
                size="114"
                variant="light"
                :src="viewEmployee.avatar"
              />
            </div>
          </div>
          <h3>{{viewEmployee.name}}</h3>
          <h6 class="text-muted">
            {{viewEmployee.email}}
          </h6>
          <b-badge
            class="profile-badge"
            variant="light-primary"
          >
          {{viewEmployee.designation}}
          </b-badge>
          <hr class="mb-2">

          <!-- follower projects rank -->
            <div>
              <b>Employee Details</b>
              <b-list-group class="mt-1">
                <b-list-group-item >
                  <b-row >
                    <b-col cols="4" >
                      Bio : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.bio }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Date of Join :
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.date_of_join }}
                    </b-col>
                  </b-row> 
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Employee ID : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.employee_id }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Reporting Manager : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.manager }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Zircly Role : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.role }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Division : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.division }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Department : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.department }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Shift : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.shift }}
                      <span>
                        ({{ viewEmployee.shift_details.session_1_start_from | formatTime }} -
                        {{ viewEmployee.shift_details.session_2_end_at | formatTime }})
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
        </b-card>
      </template>
    </b-sidebar>
    <!-- OKR Sidebar Detailed View -->
    <b-modal :id="'tree-objective-'+parent" modal-class="okr-list-details" hide-footer v-if="selectedNodes && selectedNodes.length && selectedNodes[0]">
      <template #modal-title #modal-header="{ close }" >
        <h5 class="modal-title" v-if="selectedNodes && selectedNodes.length > 0 && selectedNodes[0]"><span>{{selectedNodes[0].user.name}}</span>’s {{selectedNodes[0].quarter}}, {{selectedNodes[0].year}} {{ selectedNodes[0].type }} OKR</h5>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20"
          @click="$bvModal.hide('tree-objective-'+parent)" />
      </template>
      <!-- Fixed Footer Related Okr Content -->
      <ul class="m-0 p-1 tree" v-if="selectedNodes && selectedNodes.length && selectedNodes[0]">
        <li class="list-unstyled mb-75" v-for="objective in selectedNodes">
          <div class="okr-detailview position-relative">
            <div class="progress-state">
              <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
            </div>
            <b-row class="d-flex align-items-center justify-content-between w-100 m-0 after-expand" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <b-col sm="10" md="10" class="pl-0 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                  <path d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z" fill="#667085" />
                </svg>
                <h5 class="main-header mb-0 w-100 pl-50">{{objective.title}}</h5>
              </b-col>
              <b-col sm="2" md="2" class="okr-status d-flex align-items-center justify-content-md-end pr-0 py-25">
                <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                  <div class="circle_inner">
                    <div class="round_per"></div>
                  </div>
                </div>
                
              </b-col>
            </b-row>
            <b-collapse :id="'KeyResultsView-' + objective.hashid" class="w-100 border-top mt-50">
                <ul class="sub-tree pl-2 pt-50">
                  <li class="list-unstyled border-0 w-100"  v-for="(key_result,k) in objective.key_results">
                    <div class="bordered-liness ">
                      <b-row class="border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                          <b-col class="align-items-center pl-0" sm="8" md="8">
                              <h5>{{key_result.result_description}}</h5>
                        </b-col>
                        <b-col sm="4" md="4" class="justify-content-md-end d-flex pr-0 text-md-right">
                          <div class="input-value">
                            <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state && key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress + " / " + key_result.goal + "%" }}</p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
        </li>
        <!-- End Update-->
      </ul>
    </b-modal>
  </div>
</template>
<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import QuillInput from "@/components/QuillInput.vue";

import $ from "jquery";
import Vue from "vue";
import { bus } from "@/main.js";
import axios from "@axios";
import VueCookies from "vue-cookies";
import Nodes from "./nodes.vue"
Vue.use(VueCookies);

export default {
name:"nodes",
  components: {
  BAvatar,
  BCard,
  BCardText,
  BButton,
  BFormTextarea,
  BImg,
  BFormGroup,
  BRow,
  BLink,
  BCol,
  BAvatarGroup,
  BEmbed,
  QuillInput,
  // Navbar Components
  BCarousel,
  BCarouselSlide,
  Nodes
  },
  directives: {
  "b-tooltip": VBTooltip,
  Ripple,
  },
  props:{
      parent:{
        required: true,
      },
      parentData:{
        required: true,
      },
      selectedPeriod:{
        required: false,
      }
  },
  data() {
  return {
    nodes:[],
    showEmpInfoSidebar:false,
    viewEmployee:null,
    selectedNodes:[],
  }
  },
  mounted() {
    var nodeId = '#company-node-'+this.parent;
    $(nodeId).hide();
    $(nodeId+'.active').hide();  
    this.$nextTick(() => {
      this.updateProgress();
    });
  },
  methods: {

    getStatusVariant(status){
      if(status == 'Upcoming'){
        return 'light-info';
      }else if(status == 'Not Started Yet'){
        return 'light-notyet';
      }else if(status == 'In Progress'){
        return 'light-warning';
      }else if(status == 'Completed'){
        return 'light-primary';
      }else if(status == 'Cancelled'){
        return 'light-danger';
      }else if(status == 'On Hold'){
        return 'light-dark';
      }else if(status == 'Deferred'){
        return 'light-dark';
      }else if(status == 'Submitted'){
        return 'light-dark';
      }else if(status == 'Approved'){
        return 'light-primary';
      }
      return 'light-warning';
    },
    openDetails(nodes){
      this.selectedNodes = nodes;
      this.$nextTick(() => {
        this.$bvModal.show('tree-objective-'+this.parent);
        this.updateProgress();
      });
    },
    getSubNodesPeople(){
      axios
      .post("companyTree/subNodes/"+this.parent,
        { 
          quarter:this.selectedPeriod.quarter,
          year:this.selectedPeriod.year
        })
      .then((response) => {
        if (response.data.success) {
          this.nodes = response.data.data;
          this.$nextTick(() => {
            this.updateProgress();
          });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to fetch Company Tree",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch Company Tree',
              icon: 'EditIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
        }
      });
    },
    async reportingListClicked(id){
      this.isloading = true;
      // await this.sleep(2000);
      var nodeId = '#person-'+id;
        $("#arrow-icon-"+id).toggleClass("activenode");
        $(nodeId).addClass("open-tree"); 
        $(nodeId).siblings('li').removeClass('open-tree');
        $(nodeId).siblings('li').find('nodes').hide();
        var children = $(nodeId).find('>div>ul');
        if (children.is(":visible")) {
          children.hide('fast').removeClass('active');
        }
        else {
          children.show('fast').addClass('active');
          try{
          this.$refs['children-' + id].getSubNodesPeople();
          }
          catch{
            this.$refs['children-' + id][0].getSubNodesPeople();
          }
        }
        const element = document.getElementById('person-'+id);
        if (element) {
          element.classList.toggle("after-expand");
          element.classList.toggle("before-expand"); // Replaces any existing class with "new-class"
        }
        this.isloading = false;      
    },
    getNodes(records,type){
      // if(this.parent != 'company' && this.parentData && records){
      //   var filteredNodes = [];
      //   var filteredDepartments = [];
      //   records.forEach(node => {
      //     if(node.hashid != this.parentData.hashid){
            
      //       if(node.division == this.parentData.division){
      //         filteredNodes.push(node);
      //       }else{
      //         if(filteredDepartments[node.division]){
      //           filteredDepartments[node.division].push(node);
      //         }else{
      //           filteredDepartments[node.division] = [node];
      //         }
      //       }
      //     }
          
      //   });
      //   if(type == 'people'){
      //     return filteredNodes;
      //   }else{
      //     return Object.entries(filteredDepartments);
      //   }
      // }
      if(type == 'people'){
          return records;
        }else{
          return [];
        }

    },
    updateProgress(){
      // Circle Progress bar Design
      $(".circle_percent").each(function() {
        var $this = $(this),
        $dataV = $this.attr('percent'),
        $dataDeg = $dataV * 3.6,
        $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
        $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
        $this.prop('Counter', 0).animate({Counter: $dataV},
        {
          duration: 2000, 
          easing: 'swing', 
          step: function (now) {
                  $this.find(".percent_text").text(Math.ceil(now)+"%");
              }
          });
        if($dataV >= 51){
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function(){
            $this.addClass("percent_more");
          },1000);
          setTimeout(function(){
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          },1000);
        } 
      });
    },
  },
}
</script>