<template>
  <div>
    <ul :id="'company-node-'+parent" >
      <li v-for="node in nodes" :id="'node-'+node.hashid" class="before-expand">
        <b-card class="relative mb-0 company-dept" v-if="node.node_type == 'employee'" :style="{borderColor:node.node_color}">
          <!-- <div class="emp-pic">
            <b-img :src="node.person.avatar" class="pro-img" fluid rounded="circle" alt="" @click="viewEmployee = node.person ; showEmpInfoSidebar = true;"></b-img>
          </div>
          <div class="view-count d-flex align-items-center justify-content-between">
            <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="people" scale="1" class="mr-50"></b-icon> <span>{{node.employees_count}}</span></b-badge>
            <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="building" scale="1" class="mr-50"></b-icon> <span>{{node.departments_count}}</span></b-badge>
          </div>
          <div class="d-flex aign-items-center justify-content-center">
            <div class="tree-content">
              <div class="mb-0 mt-1">
                <div class="goal-header text-center mt-2">
                  <h5 class="mb-0 emp-name font-weight-bolder" v-b-tooltip.hover.v-default :title="node.person.name">{{node.person.name}}</h5>
                  <h6 class="description mt-25 small">{{node.person.designation}}</h6>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Employee Add Action -->
          <!-- <div class="tree-addaction" v-if="$can('Zircly Admin')" >
                      <div class="delete-action">
                        <feather-icon icon="TrashIcon" size="14" class="mr-50 text-danger" @click="confirmDelete(node)" />
                      </div>
                      <b-dropdown class="blog-dropdown" variant="link" dropright id="dropdown-dropright" size="sm" toggle-class="text-decoration-none"
                        no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="PlusIcon" size="18" class="align-middle rounded" :style="{backgroundColor:node.node_color,color:'white'}"/>
                        </template>
                        <b-dropdown-item  v-if="employeeList.length && $can('Zircly Admin')"  class="btn-sm p-0 w-100" @click="openModel(node,'employee')" >
                          <b-icon icon="person-plus" size="13" class="mr-50 text-primary" />
                          <span class="">Add Employee</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('Zircly Admin')" class="btn-sm p-0 w-100" @click="openModel(node,'department')" >
                          <b-icon icon="building" size="13" class="mr-50 text-primary" />
                          <span class="">Add Department</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('Zircly Admin')" class="btn-sm p-0 w-100" @click="autoGenerate(node)">
                          <b-icon icon="diagram3" size="13" class="mr-50 text-primary" />
                          <span class="">Auto Generate</span>
                        </b-dropdown-item>
                      </b-dropdown>
                  </div> -->
          <!-- Static New Design -->
          <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="emp-logo d-flex align-items-center gap-6">
                    <div class="company-logo">
                      <b-img :src="companyData['logo']" class="pro-img" fluid rounded="circle" alt="company logo"></b-img>
                    </div>
                    <h2 class="mb-0">{{ companyData['name'] }}</h2>
                  </div>
                  <div class="progress-state okr-status">
                    <div class="circle_percent" :percent="Number(companyData['overall_percentage'])">
                      <div class="circle_inner">
                        <div class="round_per"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center objective-objtype">
                  <div class="obj-name d-flex align-ites-center">
                    <div class="quarter-data">
                      <h6 class="mb-0 text-left">No Of Objectives</h6>
                      <p class="value text-left mb-0">{{companyData['objectiveCount']}}</p>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="35" viewBox="0 0 2 35" fill="none">
                    <path d="M1 1L1 34" stroke="#D0D5DD" stroke-linecap="round"/>
                  </svg>
                  <div class="obj-period d-flex align-ites-center">
                    <div class="quarter-data">
                      <h6 class="mb-0 text-left">Quarter</h6>
                      <p class="value text-left mb-0">{{this.selectedPeriod.quarter}} {{this.selectedPeriod.year}}</p>
                    </div>
                  </div>
                </div>
                <div class="w-100 overall-progress pb-50">
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="mb-0">Overall Progress</h6>
                    <span class="ml-50">{{Number(companyData['overall_percentage'])}}%</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <b-progress value="50" max="100" class="flat-progress w-100"></b-progress>
                    <!-- <span class="ml-50">{{Number(companyData['overall_percentage'])}}%</span> -->
                  </div>
                </div> 
                </hr>
                <!-- Expanded List -->
                <div class="expand-list border-top pt-50 mt-50">
                  <div class="d-flex align-items-center justify-content-between list-unstyled my-25 gap-6" v-for="objective in companyData['objectives']">
                    <h6 class="mb-0">{{objective.title}}</h6>
                    <div class="progress-state okr-status">
                      <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-button :id="'arrow-icon-'+node.hashid" class="expand-icon" v-if="node.employees_count || node.departments_count" @click="listClicked(node.hashid);">
                  <feather-icon icon="PlusIcon" size="18" class="before-expand m-0"/>
                  <feather-icon icon="MinusIcon" size="18" class="after-expand m-0" />
                </b-button>
            <!-- <b-button  v-if="node.employees_count || node.departments_count" :id="'arrow-icon-'+node.hashid" class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" @click="listClicked(node.hashid);"/></b-button> -->
          </b-card>
          <div class="company-dept sub-department" v-else>
            <button class="btn dept-btn"  :style="{backgroundColor:node.node_color,color:'white'}"> <span @click="if(node.employees_count || node.departments_count){listClicked(node.hashid)}" >{{node.name}}</span> <b-badge :style="{backgroundColor:node.node_color,color:'white'}"><span>{{node.employees_count+node.departments_count}}</span></b-badge></button>
              <!-- Employee Add Action -->
              <div class="tree-addaction" v-if="$can('Zircly Admin')">
                <b-dropdown class="blog-dropdown" variant="link" dropright id="dropdown-dropright" size="sm" toggle-class="text-decoration-none"
                  no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="PlusIcon" size="18" class="align-middle rounded" :style="{backgroundColor:node.node_color,color:'white'}"/>
                  </template>
                  <b-dropdown-item v-if="employeeList.length && $can('Zircly Admin')" class="btn-sm p-0 w-100" @click="openModel(node,'employee')"  >
                    <b-icon icon="person-plus" size="13" class="mr-50 text-primary" />
                    <span class="">Add Employee</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('Zircly Admin')" class="btn-sm p-0 w-100" @click="openModel(node,'department')" >
                    <b-icon icon="building" size="13" class="mr-50 text-primary" />
                    <span class="">Add Department</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('Zircly Admin')" class="btn-sm p-0 w-100" @click="editDepartment(node)" >
                    <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                    <span class="">Edit Department</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('Zircly Admin')" class="btn-sm p-0 w-100" @click="confirmDelete(node)">
                    <feather-icon icon="TrashIcon" size="13" class="mr-50 text-danger" />
                    <span class="text-danger">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
            </div>
          </div>
          <subnodes v-if="node.employees_count || node.departments_count" :parent="node.hashid" :parentData="node" :employeeList="employeeList" :ref="'children-'+node.hashid" />
      </li>
    </ul>
    <!-- Add Employee Form Popup -->
    <b-sidebar
      :id="parent+'-addemp-popover'"
      :ref="parent+'-addemp-popover'"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="35%"
      title="Add New Employee"
      class="tree-emp text-left"
      :visible="showEmpAddSidebar"
    >
      <template #default="{ hide }">
          <!-- Header -->
        <b-link class="text-primary floating-close" @click="showEmpAddSidebar = false;">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
        <h3 class="mb-0 font-weight-bold">Add New Employee</h3>
        <b-button size="sm" @click="addNode()" class="btn-primary">Save</b-button>
      </div>
        <!-- Footer -->
        <!-- BODY -->
        <b-card class="sidebar-pop">
          <b-row>
            <b-col>
              <label>Select Employees to place under "{{parent_name}}"</label>
              <v-select
                :dir="
                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                "
                v-model="selectedEmployees"
                :options="employeeList"
                class="w-100"
                label="label"
                :reduce="(val) => val.code"
                placeholder="Select Employee"
                input-id="select-person"
                :clearable="false"
                multiple
              />
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-sidebar>
    <!-- End Add Employee Form Popup -->
    <!-- Add Department Popup -->
    <b-sidebar
    :id="parent+'-dept-popover'"
    :ref="parent+'-dept-popover'"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="35%"
      title="Add New Department"
      class="tree-emp text-left"
      :visible="showDeptAddSidebar"

    >
      <template #default="{ hide }">
            <!-- Header -->
      <b-link class="text-primary floating-close" @click="showDeptAddSidebar = false;">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
        <h3 class="mb-0 font-weight-bold">Add New Department</h3>
        <b-button size="sm" @click="addNode()" class="btn-primary">Save</b-button>
      </div>
        <!-- Footer -->
        <!-- BODY -->
        <b-card class="sidebar-pop">
          <b-row>
            <b-col sm="12">
              <label>Enter Department Name </label>
              <b-form-input
                id="name"
                v-model="department_name"
                placeholder="Department Name"
              />
            </b-col>
            <b-col sm="12" class="mt-2 ml-auto color-pick">
              <b-form-group label="Node Color" label-for="node-color">
                <colour-picker
                v-model="color" :color="color"/>
              </b-form-group>
            </b-col>
            <!-- <b-col sm="12" v-if="department_name">
              <div>
                <b-button :style="{backgroundColor:dynamicColor}" >{{department_name}} <b-badge>7</b-badge></b-button>
              </div>
            </b-col> -->
            <b-col sm="12">
              <label class="mt-2 mb-1">Preview Department</label>
              <div class="company-dept sub-department m-0">
                <button class="btn dept-btn"
                :style="{backgroundColor:color,color:'white'}">{{department_name}} <b-badge>99</b-badge></button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-sidebar>
    <!-- End Add Department Popup -->
    <!-- Edit Department Popup -->
    <b-sidebar
      :id="parent+'-edit-dept-popover'"
      :ref="parent+'-edit-dept-popover'"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="35%"
      title="Edit Department"
      class="tree-emp text-left"
      :visible="showDeptEditSidebar"

    >
      <template #default="{ hide }">
            <!-- Header -->
      <b-link class="text-primary floating-close" @click="showDeptEditSidebar = false;">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
        <h3 class="mb-0 font-weight-bold">Edit Department</h3>
        <b-button size="sm" @click="updateNode()" class="btn-primary">Update</b-button>
      </div>
        <!-- Footer -->
        <!-- BODY -->
        <b-card class="sidebar-pop">
          <b-row>
            <b-col sm="12">
              <label>Enter Department Name </label>
              <b-form-input
                id="name"
                v-model="department_name"
                placeholder="Department Name"
              />
            </b-col>
            <b-col sm="12" class="mt-2 ml-auto color-pick">
              <b-form-group label="Node Color" label-for="node-color">
                <colour-picker
                v-model="color" :color="color"/>
              </b-form-group>
            </b-col>
            <!-- <b-col sm="12" v-if="department_name">
              <div>
                <b-button :style="{backgroundColor:dynamicColor}" >{{department_name}} <b-badge>7</b-badge></b-button>
              </div>
            </b-col> -->
            <b-col sm="12">
              <label class="mt-2 mb-1">Preview Department</label>
              <div class="company-dept sub-department m-0">
                <button class="btn dept-btn"
                :style="{backgroundColor:color,color:'white'}">{{department_name}} <b-badge>99</b-badge></button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-sidebar>
    <!-- End Edit Department Popup -->
    <!-- Employee Info Popup -->
    <b-sidebar 
    :id="parent+'-profile-view-popover'"
    :visible="showEmpInfoSidebar"
    v-if="viewEmployee"
    :ref="parent+'-profile-view-popover'" bg-variant="white" shadow  no-header right width="35%"
      title="Employee Profile">
      <template>
        <!-- Header -->
        <div class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-2
              border-bottom
              sticky-top
            ">
          <h3 class="mb-0 font-weight-bold">Employee Profile</h3>
          <div>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
              @click="showEmpInfoSidebar = false">
              <feather-icon size="20" icon="XCircleIcon" />
            </b-button>
          </div>
        </div>
        <!-- BODY -->

        <b-card
          :img-src="require('@/assets/images/banner/banner-'+viewEmployee.banner_id+'.jpg')"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile sidebar-pop"
        >
          <div class="profile-image-wrapper ">
            <div class="profile-image p-0">
              <b-avatar
                size="114"
                variant="light"
                :src="viewEmployee.avatar"
              />
            </div>
          </div>
          <h3>{{viewEmployee.name}}</h3>
          <h6 class="text-muted">
            {{viewEmployee.email}}
          </h6>
          <b-badge
            class="profile-badge"
            variant="light-primary"
          >
          {{viewEmployee.designation}}
          </b-badge>
          <hr class="mb-2">

          <!-- follower projects rank -->
            <div>
              <b>Employee Details</b>
              <b-list-group class="mt-1">
                <b-list-group-item >
                  <b-row >
                    <b-col cols="4" >
                      Bio : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.bio }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Date of Join :
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.date_of_join }}
                    </b-col>
                  </b-row> 
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Employee ID : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.employee_id }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Reporting Manager : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.manager }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Zircly Role : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.role }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Division : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.division }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Department : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.department }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row >
                    <b-col cols="4">
                      Shift : 
                    </b-col>

                    <b-col cols="8">
                      {{ viewEmployee.shift }}
                      <span>
                        ({{ viewEmployee.shift_details.session_1_start_from | formatTime }} -
                        {{ viewEmployee.shift_details.session_2_end_at | formatTime }})
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
        </b-card>
      </template>
    </b-sidebar>
    <!-- Employee Info Popup -->
  </div>
</template>
<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BCarousel,
  VBToggle,
  BCarouselSlide,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import QuillInput from "@/components/QuillInput.vue";

import $ from "jquery";
import Vue from "vue";
import { bus } from "@/main.js";
import axios from "@axios";
import VueCookies from "vue-cookies";
import Subnodes from "./subnodes.vue";

import ColourPicker from "@/components/CustomColorPicker.vue";
Vue.use(VueCookies);

export default {
name:"subnodes",
  components: {
  BAvatar,
  BCard,
  BCardText,
  BButton,
  BFormTextarea,
  BImg,
  BFormGroup,
  BRow,
  BLink,
  BCol,
  BAvatarGroup,
  BEmbed,
  QuillInput,
  // Navbar Components
  BCarousel,
  BCarouselSlide,
  Subnodes,
  ColourPicker
  },
  directives: {
  "b-tooltip": VBTooltip,
  'b-toggle': VBToggle,
  Ripple,
  },
  props:{
      parent:{
        required: true,
      },
      parentData:{
        required: true,
      },
      employeeList:{
        type:Array,
        required: true,
      }
  },
  data() {
  return {
    nodes:[],
    selectedEmployees: [],
    companyData: [],
    parent_id: null,
    parent_name: null,
    department_name: null,
    node_type : null,
    node_id:null,
    color:'#38B22D',
    showDeptAddSidebar:false,
    showDeptEditSidebar:false,
    showEmpAddSidebar:false,
    showEmpInfoSidebar:false,
    viewEmployee:null,
  }
  },
  mounted() {
    var nodeId = '#company-node-'+this.parent;
    $(nodeId).hide();
    $(nodeId+'.active').hide(); 
    this.$nextTick(() => {
      this.updateProgress();
    });
  },
  methods: {
    resetVariables(){
      this.selectedEmployees =  [];
      this.parent_id =  null;
      this.parent_name =  null;
      this.department_name =  null;
      this.node_type  =  null;
      this.node_id = null;
      this.color = '#38B22D';
      this.showDeptAddSidebar = false;
      this.showDeptEditSidebar = false;
      this.showEmpAddSidebar = false;
      this.showEmpInfoSidebar = false;
    },
    getSubNodesPeople(){
      axios
      .get("companyTree/treeSubNode/"+this.parent)
      .then((response) => {
        if (response.data.success) {
          this.nodes = response.data.subnodes;
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to fetch Company Tree",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch Company Tree',
              icon: 'EditIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
        }
      });
    },
    listClicked(id){
      var nodeId = '#node-'+id;
      $("#arrow-icon-"+id).toggleClass("activenode");
      $(nodeId).addClass("open-tree"); 
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('nodes').hide();
      $(nodeId).scrollTop($(nodeId)[0].scrollHeight);
      var mydiv = $(nodeId);
      // mydiv.scrollTop(mydiv.prop("scrollHeight"));
      window.scrollTo({
        top: mydiv.prop("scrollHeight")+500,
        behavior: "smooth"
      });
      var children = $(nodeId).find('>div> ul');
      if (children.is(":visible")) {
        children.hide('fast').removeClass('active');
      }
      else {
        children.show('fast').addClass('active');
        try{
          this.$refs['children-' + id].getSubNodesPeople();
        }
        catch{
          this.$refs['children-' + id][0].getSubNodesPeople();
        }
      }

    },
    confirmDelete(node){
      if(node.node_type == 'employee'){
        this.$swal({
          title: "Are you sure?",
          text: `You want to delete employee node : ${node.person.name}.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.deleteNode(node.hashid);
          }
        });
      }else if(node.node_type == 'department'){
        this.$swal({
          title: "Are you sure?",
          text: `You want to delete department node : ${node.name}.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.deleteNode(node.hashid);
          }
        });
      }
      
    },
    openModel(node,type){
      this.node_type = type;
      this.parent_id =   node.hashid;
      this.parent_name =  (node.person) ? node.person.name:node.name;
      if(type == 'employee'){
        this.showEmpAddSidebar = true;
      }else{
        this.showDeptAddSidebar = true;
      }
    },
    editDepartment(node){
      this.color = node.node_color;
      this.node_id =   node.hashid;
      this.node_type = 'department';
      this.department_name =  (node.person) ? node.person.name:node.name;
      this.showDeptEditSidebar = true;
    },
    deleteNode(nodeID){
      axios
      .delete("company-trees/"+nodeID)
      .then((response) => {
        if (response.status == "204") {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Node Deleted successfully",
          //     icon: "EditIcon",
          //     variant: "primary",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Node Deleted successfully',
              icon: 'EditIcon',
              variant: 'danger',
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
          });
          try{
            this.getSubNodesPeople();
            this.$parent.getSubNodesPeople();
          }
          catch{
            this.$parent.getTreeSubNode();
          }
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to delete Node",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text:response.data.message
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to delete Node',
              icon: 'EditIcon',
              variant: 'danger',
              text: error,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
        }
      });
    },
    updateProgress(){
      // Circle Progress bar Design
      $(".circle_percent").each(function() {
        var $this = $(this),
        $dataV = $this.attr('percent'),
        $dataDeg = $dataV * 3.6,
        $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
        $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
        $this.prop('Counter', 0).animate({Counter: $dataV},
        {
          duration: 2000, 
          easing: 'swing', 
          step: function (now) {
                  $this.find(".percent_text").text(Math.ceil(now)+"%");
              }
          });
        if($dataV >= 51){
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function(){
            $this.addClass("percent_more");
          },1000);
          setTimeout(function(){
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          },1000);
        } 
      });
    },
    addNode(){
      if(this.parent_id && (this.selectedEmployees.length || this.department_name)){
        var data = {
          parent_id : this.parent_id,
          people : this.selectedEmployees,
          name : this.department_name,
          node_color : this.color,
          node_type : this.node_type
        }
        axios
          .post("companyTree/addNode",data)
          .then((response) => {
            if (response.data.success) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Node added to the Chart",
              //     icon: "CheckIcon",
              //     variant: "success",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Node added to the Chart',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 successful-info",
            hideProgressBar : true,
          });
              this.getSubNodesPeople();
              try{
                this.$refs['children-' + this.parent_id][0].getSubNodesPeople();
              }catch{
                this.$refs['children-' + this.parent_id].getSubNodesPeople();
              }
              this.fetchEmployeeList();
              this.resetVariables();
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to fetch Company Tree",
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch Company Tree',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
            }
          });
      }else{
        if(!this.parent_id){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Parent Node is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Parent Node is not selected properly`,
                  icon:'EditIcon',
                  variant : 'danger',
                  hideClose: true,
                  
                },
                    },
                    {
                timeout : 3000,
                position : 'bottom-center',
                toastClassName:"error-info",
                hideProgressBar : true,
                    })
        }
        if(!this.selectedEmployees.length && this.node_type == 'employee'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Employee is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Employee is not selected properly`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        }
        if(!this.department_name && this.node_type == 'department'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Name is required",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Department Name is required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        }
      }
    },
    updateNode(){
      if(this.node_id && this.department_name){
        var data = {
          node_id : this.node_id,
          people : this.selectedEmployees,
          name : this.department_name,
          node_color : this.color,
          node_type : this.node_type
        }
        axios
          .post("companyTree/updateNode",data)
          .then((response) => {
            if (response.data.success) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Node Updated",
              //     icon: "CheckIcon",
              //     variant: "success",
              //     text: response.data.message,
              //   },
              // });

               
               
              this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Node Updated',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 successful-info",
            hideProgressBar : true,
          });
                
              this.getSubNodesPeople();
              try{
                this.$refs['children-' + this.parent_id][0].getSubNodesPeople();
              }catch{
                this.$refs['children-' + this.parent_id].getSubNodesPeople();
              }
              this.fetchEmployeeList();
              this.resetVariables();
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to fetch Company Tree",
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: response.data.message,
              //   },
              // });

              this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch Company Tree.',
              icon: 'EditIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
          
               
            }
          });
      }else{
        if(!this.node_id){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Node is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Node is not selected properly',
              icon: 'EditIcon',
              variant: 'danger',
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
          });
        }
        if(!this.department_name && this.node_type == 'department'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Name is required",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Department Name is required',
              icon: 'EditIcon',
              variant: 'danger',
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
          });
        }
      }
    },
    autoGenerate(node){
      axios
      .get("companyTree/autoGenerate/"+node.hashid)
      .then((response) => {
        if (response.data.success) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Subordinates Added to the Chat",
          //     icon: "CheckIcon",
          //     variant: "success",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Subordinates Added to the Chat',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 successful-info",
            hideProgressBar : true,
          });
          this.getSubNodesPeople();
          try{
            this.$refs['children-' + node.hashid][0].getSubNodesPeople();
          }catch{
            this.$refs['children-' + node.hashid].getSubNodesPeople();
          }
          this.fetchEmployeeList();
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to fetch Company Tree",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch Company Tree',
              icon: 'EditIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
        }
      });
    },
    fetchEmployeeList() {
      axios
        .get("list/tree-available-employees")
        .then((response) => {
          if (response.data.success) {
            this.employeeList = response.data.data;
          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch available employee list for tree ",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to fetch available employee list for tree',
              icon: 'EditIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName:"radius-8 error-info ",
            hideProgressBar : true,
          });
          }
        });
    },
    expandAll(){
      var nodeId = '#company-node-'+this.parent;
      $(nodeId).show();
      $(nodeId+'.active').show(); 
      this.$refs['children-'+this.parent].expandAll();
    },
    collapseAll(){
    $('.okr-company ul').hide();
    $('.okr-company ul.active').hide();
    }
  },
}
</script>